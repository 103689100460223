<template>
  <v-stepper v-model="status.statusId" class="elevation-0" :alt-labels="!$vuetify.breakpoint.smAndDown">
    <v-stepper-header>
      <template v-for="n in steps">
        <v-stepper-step
          complete-icon="mdi-check-bold"
          :color="status.statusColor"
          :complete="status.statusId > n"
          :step="n"
          :editable="editable && status.statusId !== n"
          edit-icon="mdi-check-bold"
          @click="editable?$emit('update-status',n):null">
          {{statuses[n-1].statusName}}
        </v-stepper-step>
        <v-divider
          v-if="n !== steps"
          :key="n">
        </v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
  export default {
    name: "StatusStepper",
    props: ['statuses', 'status', 'editable'],
    data() {
      return {
        statusName: '',
        steps: 4,
      }
    },
  }
</script>
