<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px">
        </v-img>
        <v-card-title>
          <span class="headline">{{$t('dialogs.create-payer.create-payer')}}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="createdPayer.payerName" :label="$t('dialogs.create-payer.payer-name')" clearable/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="createdPayer.comment" :label="$t('dialogs.create-payer.comment')" clearable/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="$emit('close-dialog')">
            {{$t('buttons.close')}}
          </v-btn>
          <v-btn color="primary" v-on:click="$emit('create-payer',createdPayer)">
            {{$t('buttons.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['open-dialog'],
    name: "CreatePayerDialog",
    data: () => ({
      createdPayer: [],
    }),
  }
</script>
