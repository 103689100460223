var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('v-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-img',{attrs:{"src":require(("@/assets/UI-images/" + _vm.imgName)),"height":"100px"}}),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Clone Manager ("+_vm._s(_vm.copyObjectData.surname)+")")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Manager","clearable":""},model:{value:(_vm.clonedObject.surname),callback:function ($$v) {_vm.$set(_vm.clonedObject, "surname", $$v)},expression:"clonedObject.surname"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.agencies,"item-value":"agencyId","label":"Agency","item-text":"agencyName"},model:{value:(_vm.agencyId),callback:function ($$v) {_vm.agencyId=$$v},expression:"agencyId"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"rules":{required:true,email:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","clearable":""},model:{value:(_vm.clonedObject.email),callback:function ($$v) {_vm.$set(_vm.clonedObject, "email", $$v)},expression:"clonedObject.email"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Mobile","clearable":""},model:{value:(_vm.clonedObject.mobilePhone),callback:function ($$v) {_vm.$set(_vm.clonedObject, "mobilePhone", $$v)},expression:"clonedObject.mobilePhone"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Comment","clearable":""},model:{value:(_vm.clonedObject.comment),callback:function ($$v) {_vm.$set(_vm.clonedObject, "comment", $$v)},expression:"clonedObject.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.$emit('clone-object',_vm.clonedObject)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.clone'))+" ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }