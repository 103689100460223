<template>
  <Loader type="order" v-if="loading"></Loader>
  <v-container v-else-if="!loading" fluid>
    <StatusStepper :statuses="statuses" :status="status" v-on:update-status="paymentDataCheck($event)"
                   :editable="!readonly"
                   v-if="status"></StatusStepper>
    <CreatePayerDialog v-bind:open-dialog="payerDialog" v-on:close-dialog="payerDialog=false"
                       v-on:create-payer="createPayer($event)"></CreatePayerDialog>
    <AddOrderFilesDialog v-bind:open-dialog="dialog" v-on:close-dialog="dialog=false"
                         :order="order" v-on:refresh-file-list="refreshFileList()"></AddOrderFilesDialog>
    <!--    <DeleteConfirmationDialog :object-name="fileObject" :file="selectedFile"-->
    <!--                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"-->
    <!--                              v-on:delete-object="deleteFile()"></DeleteConfirmationDialog>-->
    <PaymentConfirmationDialog v-bind:open-dialog="paymentConfirmDialog" v-on:close-dialog="paymentConfirmDialog=false"
                               v-on:confirm-status="update(status.statusId)"></PaymentConfirmationDialog>
    <DeleteConfirmationDialog :object-name="'order'" :order="order"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
    <!--    <CloneOrderDialog v-bind:open-dialog="cloneDialog" v-on:close-dialog="cloneDialog=false"-->
    <!--                        :agencyId="agency.agencyId"-->
    <!--                        :agencies="agencies" :manager="manager"-->
    <!--                        v-on:clone-object="cloneManager($event)"></CloneOrderDialog>-->
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card color="primary">
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col md="8" sm="8">
                <v-chip v-if="(status && duration<0 && (status.statusName==='New'||status.statusName==='In progress'))"
                        color=red text-color="white" style="text-transform: uppercase;margin-bottom: 5px" label>
                  <h2>{{$t('order-card.overdue')}}</h2>
                </v-chip>
                <div class="font-weight-light" style="text-transform: uppercase">
                  {{$t('order-card.order')}}: <h3>{{ order.orderCode }}
                  {{order.incomingNumber?'('+order.incomingNumber+')':null}}</h3>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4" sm="4">
                <v-chip :color=status.statusColor text-color="white" style="float: right;text-transform: uppercase"
                        label>
                  <h2>{{ status.statusName }}</h2>
                </v-chip>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row class="ma-auto">
              <v-col md="4">
                <div class="subtitle-1 font-weight-light">
                  {{$t('order-card.order-date')}}: <h3>{{parseDate(order.orderDate)}}</h3>
                  [{{timeElapsed(order.orderDate)}}]
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4">
                <div class="subtitle-1 font-weight-light">
                  {{$t('order-card.finish-time')}}: <h3>{{ parseTime(order.finishTime) }}</h3>
                  <div v-if="duration>0" style="float: right">[{{$t('order-card.time-left')}}: {{timeToDeadline}}]</div>
                </div>
              </v-col>
            </v-row>

            <v-row class="ma-auto">
              <v-col md="4" sm="6">
                <router-link :to="{ path: '/agency/' + agency.agencyId}">
                  <div class="subtitle-1 font-weight-light">
                    {{$t('order-card.agency')}}: <h3>{{ agency.agencyName }}</h3>
                  </div>
                </router-link>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4" sm="6" v-if="manager">
                <router-link :to="{path: '/manager/' + manager.managerId}">
                  <div class="subtitle-1 font-weight-light" style="float: right">
                    {{$t('order-card.manager')}}: <h3>{{ manager.surname }}</h3>
                  </div>
                </router-link>
              </v-col>
            </v-row>

            <v-row class="ma-auto">
              <v-col md="4" sm="6">
                <div class="subtitle-1 font-weight-light">
                  {{$t('order-card.cost')}}: <h3>{{ replaceFunction (order.totalCost) }}</h3>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col md="4" sm="6">
                <div class="subtitle-1 font-weight-light" style="float: right">
                  {{$t('order-card.pages')}}: <h3>{{ order.pageCount }}</h3>
                </div>
              </v-col>
            </v-row>

            <v-row class="ma-auto">
              <!--              <v-col md="4">-->
              <!--                <EmailTemplateDropdown :templates="templates" :order="order" :agency="agency"-->
              <!--                                       v-on:copy-text="copyText($event)"></EmailTemplateDropdown>-->
              <!--              </v-col>-->
              <v-col md="6">
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col md="6">
                <ActionButtonGroup v-on:enable-edit="readonly=false" v-on:open-delete-dialog="openDeleteOrderDialog()"
                                   v-on:clone-dialog="cloneDialog=true" :object="'order'"
                                   v-on:show-history="showHistory"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <v-container fluid>
            <v-row dense>
              <v-tabs grow icons-and-text center-active centered show-arrows>
                <v-tab v-for="card in cards" :key="card.id">{{card.title}}
                  <v-icon :color="card.color">{{card.icon}}</v-icon>
                </v-tab>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field v-model="agency.agencyName" :label="$t('order-card.agency')" readonly/>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete
                          v-model="order.langPair" :items="langPairs" :label="$t('order-card.language')"
                          item-text="shortPairName"
                          multiple :readonly="readonly" small-chips hide-selected return-object>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="order.incomingNumber" :label="$t('order-card.incoming-no')" clearable
                          :readonly="readonly"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea v-model="order.description" class="purple-input"
                                    :label="$t('order-card.description')"
                                    counter rows="3" :readonly="readonly"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-datetime-picker v-model="order.orderDate" :label="$t('order-card.order-date')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps"
                                           :readonly="readonly">
                        </v-datetime-picker>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-datetime-picker v-model="order.finishTime" :label="$t('order-card.finish-time')"
                                           :timePickerProps="timePickerProps"
                                           :date-picker-props="datePickerProps" :text-field-props="textFieldProps"
                                           :readonly="readonly">
                        </v-datetime-picker>
                      </v-col>
                    </v-row>
                    <v-alert v-if="order.orderDate>order.finishTime"
                             dense outlined border="left" text type="error">
                      {{$t('order-card.finish-time-validation')}}
                    </v-alert>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="manager" :items="filterManagers" :label="$t('order-card.manager')"
                          item-text="surname"
                          item-value="managerId" :readonly="readonly" return-object>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="order.customerName" :label="$t('order-card.customer')" clearable
                          :readonly="readonly"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="3" sm="12">
                        <v-text-field
                          :label="$t('order-card.symbols')" v-model="order.symbolCount" v-on:input="recalculate"
                          :readonly="readonly"/>
                      </v-col>
                      <v-col cols="12" md="3" sm="12">
                        <v-text-field
                          :label="$t('order-card.price-per-page')" v-model="order.pricePerPage" v-on:input="recalculate"
                          :readonly="readonly"/>
                      </v-col>
                      <v-col cols="12" md="3" sm="12">
                        <v-text-field
                          :label="$t('order-card.pages')" v-model="order.pageCount" v-on:input="recalculate"
                          :readonly="readonly"/>
                      </v-col>
                      <v-col cols="12" md="3" sm="12">
                        <v-text-field
                          :label="$t('order-card.total-cost')" v-model="order.totalCost" v-on:input="recalculate"
                          :readonly="readonly"/>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-autocomplete :items="calculationTypes" :label="$t('order-card.calculation-type')"
                                        item-text="calculationTypeName"
                                        item-value="calculationTypeId" v-model="calculationType" :readonly="readonly"
                                        v-on:change="changeCalculation">
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="2">
                        <v-switch inset v-on:change="isFreeOrderCheck"
                                  v-model="freeOrder" :label="$t('order-card.free-order')">
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-switch inset v-on:change="recalculate" :disabled="readonly"
                                  v-model="minOrderEnabled"
                                  :label="minOrderEnabled? $t('order-card.min-order-enabled') :$t('order-card.min-order-disabled')">
                        </v-switch>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-expand-x-transition>
                          <div v-if="wppIndex!==null">
                            <validation-provider :rules="{required:true,numeric:true}" v-slot="{ errors }">
                              <v-text-field :label="$t('order-card.word-count')" v-model="wordCount"
                                            v-on:input="recalculate"
                                            :readonly="readonly"/>
                              <span class="red--text font-weight-light">{{ errors[0] }}</span>
                            </validation-provider>
                          </div>
                        </v-expand-x-transition>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-autocomplete
                          v-model="order.topic" :items="topics" :label="$t('order-card.topic')" item-text="topicName"
                          multiple
                          :readonly="readonly" clearable small-chips hide-selected return-object>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="orderType" :items="orderTypes" :label="$t('order-card.order-type')"
                          item-text="orderTypeName" :readonly="readonly" item-value="orderTypeId" return-object>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete
                          v-model="order.catTool" :items="catTools" :label="$t('order-card.cat-tool')"
                          item-text="toolName" multiple
                          :readonly="readonly" clearable small-chips hide-selected return-object>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea class="purple-input" :label="$t('order-card.comments')" clearable counter
                                    rows="2" v-model="order.comments" :readonly="readonly"/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-datetime-picker v-model="order.paymentDate" :label="$t('order-card.payment-date')"
                                           :readonly="readonly"
                                           :timePickerProps="timePickerProps" :date-picker-props="datePickerProps"
                                           :text-field-props="textFieldProps">
                        </v-datetime-picker>
                      </v-col>

                      <v-col cols="12" md="4" sm="6">
                        <v-autocomplete
                          v-model="payer" :items=filterPayers :label="$t('order-card.payer')" item-text="payerName"
                          clearable
                          :readonly="readonly" item-value="payerId" return-object>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-btn block :disabled="readonly" color="primary" @click="payerDialog = true">
                          <v-icon left dark>
                            mdi-account-plus-outline
                          </v-icon>
                          {{$t('buttons.create-payer')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>

                <v-tab-item>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$t('order-card.created-date')" v-model="createdTime" readonly/>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$t('order-card.last-modified')" v-model="modifiedTime" readonly/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>
              </v-tabs>
            </v-row>

          </v-container>
          <v-row>
            <v-col cols="6" class="text-right">
              <v-btn color="primary" class="mr-0" @click="disabled=true" :disabled="disabled" block :loading="loading">
                {{$t('buttons.cancel')}}
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn color="primary" class="mr-0" @click="update(status.statusId)" :disabled="readonly" block
                     :loading="loading">
                {{$t('buttons.save')}}
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card class="v-card-profile" color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col cols="12" md="6" sm="6" lg="6">
                <h3 style="text-transform: uppercase">
                  {{ agency.agencyName }}
                </h3>
                <h4 style="text-transform: uppercase">
                  {{ order.orderCode }} {{order.incomingNumber?'('+order.incomingNumber+')':null}}
                </h4>
              </v-col>
              <v-col cols="12" md="6" sm="6" lg="6">
                <router-link :to="{ path: '/agency/' + agency.agencyId}" class="no-underline-link">
                  <v-avatar color="primary" rounded size="100%" max-width="50">
                    <v-img :src="agencyImageRender.icon"/>
                  </v-avatar>
                </router-link>
              </v-col>
            </v-row>
          </template>

          <v-card-text class="text-center">
            <h3 class="font-weight-light grey--text">{{$t('order-card.number-of-files')}}: </h3>
            <h3 class="font-weight-medium grey--text">{{ orderFiles.length }}</h3>
          </v-card-text>

          <v-expansion-panels focusable accordion>
            <v-expansion-panel :disabled="!handleQty">
              <v-expansion-panel-header>{{$t('order-card.original')}} {{handleQty?'('+handleQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Original" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!transQty">
              <v-expansion-panel-header>{{$t('order-card.translated')}} {{transQty?'('+transQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Translated" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!referenceQty">
              <v-expansion-panel-header>{{$t('order-card.reference')}} {{referenceQty?'('+referenceQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Reference" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!tmQty">
              <v-expansion-panel-header>{{$t('order-card.tm')}} {{tmQty?'('+tmQty+')':null}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="TM" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!glossaryQty">
              <v-expansion-panel-header>{{$t('order-card.glossary')}} {{glossaryQty?'('+glossaryQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Glossary" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!financeQty">
              <v-expansion-panel-header>{{$t('order-card.finance')}} {{financeQty?'('+financeQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Finance" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :disabled="!otherQty">
              <v-expansion-panel-header>{{$t('order-card.other')}} {{otherQty?'('+otherQty+')':null}}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <RelatedFiles type="Other" :files="orderFiles"
                              v-on:open-delete-dialog="openDeleteDialog($event)"></RelatedFiles>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-col cols="12">
            <v-btn color="primary" class="mr-0" block @click="dialog = true" style="text-transform: uppercase">
              {{$t('buttons.upload-files')}}
              <v-icon right dark>
                mdi-upload
              </v-icon>
            </v-btn>
          </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import RelatedFiles from "../../components/expansion-panels/RelatedFiles";
  import AddOrderFilesDialog from "../files/AddOrderFilesDialog";
  import StatusStepper from "../../components/Steppers/StatusStepper";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import CreatePayerDialog from "../payers/CreatePayerDialog";
  import CloneOrderDialog from "./CloneOrderDialog";
  import PaymentConfirmationDialog from "./PaymentConfirmationDialog";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import EmailTemplateDropdown from "../dashboard/component/EmailTemplateDropdown";
  import Loader from "../../components/Loaders/Loader";
  import {useOrders} from "../../stores/ordersStore";
  import {usePayers} from "../../stores/payersStore";
  import {useManagers} from "../../stores/managersStore";
  import {useFiles} from "../../stores/filesStore";
  import {useTasks} from "../../stores/tasksStore";
  import {mapState} from "pinia";

  const orderStore = useOrders()
  const payerStore = usePayers()
  const managerStore = useManagers()
  const fileStore = useFiles()
  const tasksStore = useTasks()

  let moment = require('moment');

  export default {
    name: 'Order Card',
    components: {
      RelatedFiles,
      CloneOrderDialog,
      DeleteConfirmationDialog,
      CreatePayerDialog,
      PaymentConfirmationDialog,
      MenuDropdown,
      ActionButtonGroup,
      StatusStepper,
      AddOrderFilesDialog,
      Loader,
      EmailTemplateDropdown
    },
    data() {
      return {
        paymentConfirmDialog: false,
        wordCount: null,
        sppIndex: null,
        wppIndex: null,
        fpIndex: null,
        minOrderEnabled: true,
        freeOrder: false,
        calculationTypeId: '',
        calculationTypes: [],
        calculationType: [],
        origQty: 0,
        transQty: 0,
        referenceQty: 0,
        tmQty: 0,
        glossaryQty: 0,
        financeQty: 0,
        otherQty: 0,
        copiedText: '',
        fileObject: '',
        loading: false,
        disabled: true,
        readonly: true,
        order: [],
        selectedFile: null,
        deleteDialog: false,
        payerDialog: false,
        dialog: false,
        timePickerProps: {
          format: "24hr"
        },
        datePickerProps: {
          firstDayOfWeek: 1
        },
        textFieldProps: {
          prependIcon: 'mdi-clock-time-four-outline',
          clearable: false
        },
        timeToDeadline: null,
        createdTime: '',
        modifiedTime: '',
        paymentDate: '',
        orderDate: '',
        finishTime: '',
        duration: 1,
        filteredPayers: [],
        filteredManagers: [],
        agency: [],
        status: [],
        manager: [],
        payer: [],
        orderType: [],
        orderHistory: [],
        langPairs: [],
        topicIds: [],
        toolIds: [],
        langIds: [],
        topics: [],
        catTools: [],
        statuses: [],
        orderTypes: [],
        templates: [],
        orderFiles: [],
        cards: [
          {
            id: 1,
            title: this.$t('order-card-tabs.main-info'),
            color: 'indigo',
            flex: 12,
            icon: 'mdi-folder-information-outline'
          },
          {id: 2, title: this.$t('order-card-tabs.dates'), color: 'green', flex: 12, icon: 'mdi-calendar-clock'},
          {
            id: 3,
            title: this.$t('order-card-tabs.personalities'),
            color: 'deep-orange',
            flex: 12,
            icon: 'mdi-account-group'
          },
          {id: 4, title: this.$t('order-card-tabs.statistics'), color: 'blue', flex: 12, icon: 'mdi-finance'},
          {id: 5, title: this.$t('order-card-tabs.extras'), color: 'blue-grey', flex: 12, icon: 'mdi-view-grid-plus'},
          {
            id: 6,
            title: this.$t('order-card-tabs.comments'),
            color: 'grey',
            flex: 12,
            icon: 'mdi-comment-processing-outline'
          },
          {id: 7, title: this.$t('order-card-tabs.payment'), color: 'cyan', flex: 12, icon: 'mdi-cash-multiple'},
          {id: 8, title: this.$t('order-card-tabs.system-info'), color: 'amber', flex: 12, icon: 'mdi-table-cog'},
        ],
      }
    },
    methods: {
      deleteObject() {
        let orderId = this.order.orderId;
        let action = 'delete';
        let objectType = 'Order';
        let params = new URLSearchParams();
        params.append('orderId', orderId);
        AXIOS.delete('/api/deleteOrder', {params})
          .then(response => {
            orderStore.deleteOrder(orderId);
            fileStore.deleteMultipleFiles(this.orderFiles);
            this.tasks.length ? tasksStore.deleteMultipleTasks(this.tasks.filter(e => e.orderId === orderId)) : null;
            this.userNotification(response.data, action, objectType);
            this.$router.push({name: 'Orders'})
          })
      },
      paymentDataCheck(newStatusId) {
        let newStatus = this.statuses.filter(e => e.statusId === newStatusId);
        if (newStatus[0].statusName === 'Paid' && (!this.order.paymentDate || this.order.paymentDate === '' || !this.payer)) {
          this.paymentConfirmDialog = true;
        } else {
          this.update(newStatusId)
        }
      },
      isFreeOrderCheck() {
        if (this.freeOrder) {
          this.order.pricePerPage = 0;
          this.order.totalCost = 0;
        }
        if (!this.freeOrder) {
          this.order.pricePerPage = null;
        }
      },
      changeCalculation() {
        if (this.calculationTypes.length > 1) {
          console.log("cat:", this.calculationType)
          let calcType = this.calculationTypes.filter(e => e.calculationTypeId === this.calculationType);
          if (calcType[0].perSymbol) {
            console.log('isPerSymbol:' + calcType[0].perSymbol)
            this.sppIndex = calcType[0].symbolsPerPage;
            this.wppIndex = null;
            this.fpIndex = null;
          }
          if (calcType[0].perWord) {
            console.log('isPerWord:' + calcType[0].perWord)
            this.wppIndex = calcType[0].wordsPerPage;
            this.sppIndex = null;
            this.fpIndex = null;
          }
          if (calcType[0].fixedPrice) {
            console.log('isFixedPrice:' + calcType[0].fixedPrice)
            this.fpIndex = calcType[0].fixedPriceValue;
            this.wppIndex = null;
            this.sppIndex = null;
          }
          this.recalculate(calcType[0]);
          console.log('spp:' + this.sppIndex)
          console.log('wpp:' + this.wppIndex)
          console.log('fp:' + this.fpIndex)
        }
      },
      showHistory() {
        this.$router.push({path: '/history/' + this.order.orderId})
      },
      copyText(text) {
        this.copiedText = text;
      },
      openDeleteOrderDialog() {
        this.deleteDialog = true;
      },
      openDeleteDialog(file) {
        this.selectedFile = file;
        this.deleteDialog = true;
        this.fileObject = 'file';
      },
      deleteFile() {
        let action = 'delete';
        let objectType = 'File';
        let params = new URLSearchParams();
        params.append('fileId', this.selectedFile.fileId);
        this.removeFileFromFS(this.selectedFile);
        AXIOS.delete('/api/deleteFile', {params});
        fileStore.deleteFile(this.selectedFile.fileId)
        this.removeFileFromArray(this.selectedFile.fileId);
        this.deleteDialog = false;
        this.userNotification('response', action, objectType);
      },
      removeFileFromFS(file) {
        let params = new URLSearchParams();
        params.append('fileId', file.fileId);
        AXIOS.post('/api/removeFileFromFS', params)
          .then(response => {
            this.response = response.data;
          })
      },
      refreshFileList() {
        this.orderFiles = this.files.filter(e => e.order.orderId == this.$route.params.orderId);
      },
      removeFileFromArray(fileId) {
        let elIndex = this.orderFiles.findIndex((elem => elem.fileId === fileId));
        this.orderFiles.splice(elIndex, 1);
      },
      recalculate(calcType) {
        let order = this.order;
        if (this.sppIndex !== null) {
          console.log('case1')
          order.pageCount = (order.symbolCount / this.sppIndex).toFixed(2);
          order.totalCost = (order.pricePerPage * order.pageCount).toFixed(0);
          if (this.minOrderEnabled) {
            if (order.symbolCount > 0 && order.pageCount < 1) {
              order.totalCost = order.pricePerPage * 1;
            }
          }
        }
        if (this.wppIndex !== null) {
          console.log('case2')
          order.pageCount = (order.wordCount / order.wppIndex).toFixed(2);
          order.totalCost = (order.pricePerPage * order.pageCount).toFixed(0);
          if (this.minOrderEnabled) {
            if (order.symbolCount > 0 && order.pageCount < 1) {
              order.totalCost = order.pricePerPage * 1;
            }
          }
        }
        if (this.fpIndex !== null) {
          console.log('case3')
          this.minOrderEnabled = false;
          order.pageCount = (order.symbolCount / 1800).toFixed(2);
          order.totalCost = calcType.fixedPriceValue;
          order.pricePerPage = 0;
        }
        if (this.wppIndex === null && this.sppIndex === null && this.fpIndex === null) {
          console.log('case4')
          order.pageCount = (order.symbolCount / 1800).toFixed(2);
          order.totalCost = (order.pricePerPage * order.pageCount).toFixed(0);
          if (this.minOrderEnabled) {
            if (order.symbolCount > 0 && order.pageCount < 1) {
              order.totalCost = order.pricePerPage * 1;
            }
          }
        }
      },
      calculateTimeToDeadline(finishTime) {
        let result;
        let diff = moment(finishTime).diff(moment(), 'milliseconds');
        let duration = moment.duration(diff);
        let months = duration.months();
        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        if (months > 0) {
          result = months + ' months, ' + days + ' days, ' + hours + ' hours, ' + minutes + ' minutes'
        }
        if (days > 0 && months === 0) {
          result = days + ' days, ' + hours + ' hours, ' + minutes + ' minutes'
        }
        if (days === 0) {
          result = hours + ' hours, ' + minutes + ' minutes'
        }
        this.duration = 1;
        if (duration < 0) {
          this.duration = -1;
        }
        return this.timeToDeadline = result;
      },
      timeElapsed(time) {
        return moment(time).fromNow();
      },
      parseTime(time) {
        let format = 'YYYY-MM-DD HH:mm';
        let order = this.order;
        order.orderDate = moment(order.orderDate).format(format);
        if (order.paymentDate !== null) {
          order.paymentDate = moment(order.paymentDate).format(format);
        }
        order.finishTime = moment(order.finishTime).format(format);
        this.createdTime = moment(order.createdTime).format("DD.MM.YYYY HH:mm");
        this.modifiedTime = moment(order.modifiedTime).format("DD.MM.YYYY HH:mm");
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
      parseDate(date) {
        return moment(date).format("DD.MM.YYYY");
      },
      replaceFunction(number) {
        number = "" + number;
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      },
      getEntities(order) {
        this.agency = order.agency;
        this.status = order.status;
        this.manager = order.manager;
        this.payer = order.payer;
        this.orderType = order.orderType;
        this.calculationType = order.calculationType;
      },
      update(statusId) {
        let newStatus = this.statuses.filter(e => e.statusId === statusId);
        this.paymentConfirmDialog = false;
        this.loading = true;
        let action = 'update';
        let objectType = 'Order';
        let order = this.order;
        let format = "YYYY-MM-DDTHH:mm:ss";
        order.orderDate = moment(order.orderDate).format(format);
        order.finishTime = moment(order.finishTime).format(format);
        if (order.paymentDate !== null) {
          order.paymentDate = moment(order.paymentDate).format(format);
        }

        if (order.paymentDate === null) {
          order.paymentDate = '';
        }
        let topicArr = (order.topic).map(e => e.topicId);
        let langArr = (order.langPair).map(e => e.langPairId);
        let catToolArr = (order.catTool).map(e => e.toolId);
        let orderTypeArr = this.orderType.orderTypeId;
        let managerArr = this.manager.managerId;
        console.log("manager:", this.manager.managerId)
        console.log("arr:", managerArr)

        let params = new URLSearchParams();
        params.append('orderId', order.orderId);
        params.append('orderCode', order.orderCode);
        params.append('agencyId', this.agency.agencyId);
        params.append('description', order.description);
        params.append('pricePerPage', order.pricePerPage);
        params.append('pageCount', order.pageCount);
        params.append('symbolCount', order.symbolCount);
        params.append('totalCost', order.totalCost);
        params.append('managerId', managerArr);
        params.append('incomingNumber', order.incomingNumber);
        params.append('statusId', statusId);
        params.append('comments', order.comments);
        params.append('customerName', order.customerName);
        params.append('orderTypeId', orderTypeArr);

        if (this.payer) {
          params.append('payerId', this.payer.payerId);
          console.log("payer:", this.payer.payerId)
        }
        if (!this.payer) {
          console.log("no payer:")
          params.append('payerId', '');
        }
        params.append('langPair', langArr);
        params.append('topic', topicArr);
        params.append('catTool', catToolArr);
        params.append('paymentDate', order.paymentDate);
        params.append('orderDate', order.orderDate);
        params.append('finishTime', order.finishTime);

        if (this.order.calculationType) {
          params.append('calculationTypeId', this.calculationType.calculationTypeId);
        }
        if (!this.order.calculationType) {
          params.append('calculationTypeId', this.calculationType);
        }
        newStatus[0].statusName === 'Paid' ? params.append('paid', true) : params.append('paid', false);
        AXIOS.post(`/api/updateOrder`, params)
          .then(response => {
            this.order = response.data;
            orderStore.patchOrder(response.data)
            this.getEntities(response.data);
            this.calculateTimeToDeadline(this.order.finishTime);
            this.userNotification(response, action, objectType);
            this.readonly = true;
            this.loading = false;
          })
      },
      createPayer(payer) {
        let action = 'create';
        let objectType = 'Payer';
        let params = new URLSearchParams();
        params.append('agencyId', this.agency.agencyId);
        params.append('payerName', payer.payerName);
        params.append('comment', payer.comment);
        params.append('userId', this.currentUser.id);
        AXIOS.post(`/api/createPayer`, params)
          .then(response => {
            this.userNotification(response, action, objectType);
            payerStore.addPayer(response.data);
            this.payerDialog = false;
            this.payer = response.data;
          });
        payer.payerName = null;
        payer.comment = null;
      },
      async updateNeededCheck() {
        if (this.orders.length) {
          this.loading = true;
          this.order = this.orders.filter(e => e.orderId == this.$route.params.orderId)[0];
          this.getEntities(this.order);
          this.calculateTimeToDeadline(this.order.finishTime);
          this.loading = false;
        }
        if (!this.orders.length) {
          this.loading = true;
          let res = await orderStore.getOrdersAsync()
          this.order = this.orders.filter(e => e.orderId == this.$route.params.orderId)[0];
          this.getEntities(this.order);
          this.calculateTimeToDeadline(this.order.finishTime);
          this.loading = false;
        }
        if (this.files.length) {
          this.orderFiles = this.files.filter(e => e.order.orderId == this.$route.params.orderId)
        }
        if (!this.files.length) {
          let res = await fileStore.getFilesAsync()
          this.orderFiles = this.files.filter(e => e.order.orderId == this.$route.params.orderId);
        }
        if (!this.managers.length) {
          managerStore.populateManagers()
        }
        if (!this.payers.length) {
          payerStore.populatePayers()
        }
      },
    },
    computed: {
      ...mapState(useManagers, ['managers']),
      ...mapState(usePayers, ['payers']),
      ...mapState(useOrders, ['orders']),
      ...mapState(useFiles, ['files']),
      ...mapState(useTasks, ['tasks']),
      handleQty() {
        this.origQty = this.orderFiles.filter(e => e.fileType === 'Original').length
        this.transQty = this.orderFiles.filter(e => e.fileType === 'Translated').length
        this.referenceQty = this.orderFiles.filter(e => e.fileType === 'Reference').length
        this.glossaryQty = this.orderFiles.filter(e => e.fileType === 'Glossary').length
        this.tmQty = this.orderFiles.filter(e => e.fileType === 'TM').length
        this.financeQty = this.orderFiles.filter(e => e.fileType === 'Finance').length
        this.otherQty = this.orderFiles.filter(e => e.fileType === 'Other').length
        return this.origQty;
      },
      filterPayers: function () {
        this.filteredPayers = this.payers.filter(e => e.agency.agencyId === this.agency.agencyId)
        return this.filteredPayers
      },
      filterManagers: function () {
        this.filteredManagers = this.managers.filter(e => e.agency.agencyId === this.agency.agencyId)
        return this.filteredManagers
      },
      agencyImageRender() {
        return {
          ...this.agency,
          icon: this.agency.logoPath && require(`@/assets/agencylogos/${this.agency.logoPath}`)
        }
      }
    },
    created() {
      this.updateNeededCheck()
      this.getStatusList();
      this.getLanguageList();
      this.getTopicList();
      this.getOrderTypes();
      this.getCatToolList();
      this.getAllTemplates();
      this.getAllCalculationTypes();
    },
  }
</script>
<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
