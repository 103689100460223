<template>
    <v-menu :close-on-click="closeOnClick">
        <template v-slot:activator="{ on, attrs }">
            <v-btn outlined tile block v-bind="attrs" v-on="on">
                TEMPLATES
                <v-icon right dark> mdi-chevron-down</v-icon>
            </v-btn>
        </template>
        <v-list nav dense>
            <v-list-item-group
                    color="primary">
                <v-list-item
                        v-for="(item, index) in templates"
                        :key="index">
                    <v-list-item-content>
                        <v-list-item-title v-text="item.templateName"
                                           v-on:click="copyToClipboard(item.description)"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "EmailTemplateDropdown",
        props: ['templates', 'order', 'agency'],
        data() {
            return {
                closeOnClick: true,
                templateName: '',
            }
        },
        methods: {
            copyToClipboard(descr) {
                this.$emit('copy-text',descr);
                const el = document.createElement('textarea');
                el.value = descr;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        }
    }
</script>

<style scoped>

</style>
