<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="400px">
      <v-card>
        <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px">
        </v-img>
        <v-card-title>
          <span class="headline">{{$t('dialogs.payment-confirmation.confirm-text')}}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card-text>{{$t('dialogs.payment-confirmation.warning-text')}}</v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-on:click="$emit('close-dialog')">
            {{$t('buttons.cancel')}}
          </v-btn>
          <v-btn color="red" text v-on:click="$emit('confirm-status')">
            {{$t('buttons.confirm-status')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ['open-dialog'],
    name: "PaymentConfirmationDialog",
  }
</script>
