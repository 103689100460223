<template>
  <v-list three-line>
    <template v-for="(file, fileId) in filterFiles()">
      <v-list-item :key="file.fileId">
        <v-list-item-avatar>
          <!--                  <v-img :src="require(`@/assets/filelogos/noext.svg`)"></v-img>-->
          <v-img v-if="file.fileExtension ==='zip'||file.fileExtension ==='ZIP'"
                 :src="require(`@/assets/filelogos/zip.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='rar'||file.fileExtension ==='RAR'"
                 :src="require(`@/assets/filelogos/rar.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='png'||file.fileExtension ==='PNG'"
                 :src="require(`@/assets/filelogos/png.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='bmp'||file.fileExtension ==='BMP'"
                 :src="require(`@/assets/filelogos/bmp.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='pdf'||file.fileExtension ==='PDF'"
                 :src="require(`@/assets/filelogos/pdf.png`)"></v-img>
          <v-img v-if="file.fileExtension ==='gif'||file.fileExtension ==='GIF'"
                 :src="require(`@/assets/filelogos/gif.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='html'" :src="require(`@/assets/filelogos/html.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='txt'||file.fileExtension ==='TXT'"
                 :src="require(`@/assets/filelogos/txt.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='psd'" :src="require(`@/assets/filelogos/psd.svg`)"></v-img>
          <v-img
            v-if="file.fileExtension ==='jpg'||file.fileExtension ==='JPG'||file.fileExtension ==='jpeg'||file.fileExtension ==='JPEG'"
            :src="require(`@/assets/filelogos/jpg.svg`)"></v-img>
          <v-img v-if="file.fileExtension ==='jpeg'" :src="require(`@/assets/filelogos/jpg.svg`)"></v-img>
          <v-img
            v-if="file.fileExtension ==='ppt' || file.fileExtension ==='pptx'||file.fileExtension ==='PPT' || file.fileExtension ==='PPTX'"
            :src="require(`@/assets/filelogos/ppt.png`)"></v-img>
          <v-img
            v-if="file.fileExtension ==='xls' || file.fileExtension ==='xlsx'||file.fileExtension ==='XLS' || file.fileExtension ==='XLSX'"
            :src="require(`@/assets/filelogos/excel.png`)"></v-img>
          <v-img
            v-if="file.fileExtension ==='doc' || file.fileExtension ==='docx'||file.fileExtension ==='DOC' || file.fileExtension ==='DOCX'"
            :src="require(`@/assets/filelogos/word.png`)"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <router-link :to="{ path: '/file/' + file.fileId}" class="no-underline-link">
            <v-list-item-title v-text="file.fileName"></v-list-item-title>
          </router-link>
          <v-list-item-subtitle v-if="file.description"
                                v-text="'Description: '+ file.description"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="'Size: '+ calculateSize(file.fileSize) + ' Kb'"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="'Created: '+ parseTime(file.createdTime)"></v-list-item-subtitle>
          <v-list-item-subtitle v-text="'Modified: '+ parseTime(file.modifiedTime)"></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon outlined>
            <v-icon color="red" v-on:click="$emit('open-delete-dialog',file)">mdi-window-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="fileId" inset="inset">
      </v-divider>
    </template>
  </v-list>
</template>

<script>

  let moment = require('moment');

  export default {
    props: ['files', 'type'],
    name: "OrderRelatedRecords",
    data() {
      return {}
    },
    methods: {
      filterFiles() {
        return this.files.filter(e => e.fileType === this.type)
      },
      calculateSize(size) {
        size = (size / 1024).toFixed(1);
        return size.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      },
      parseTime(time) {
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
