<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-container fluid>
          <v-img :src="require(`@/assets/UI-images/${imgName}`)" height="100px">
          </v-img>
          <v-card-title>
            <span class="headline" style="text-transform: uppercase">{{$t('dialogs.add-order-files.add-order-files')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-radio-group v-model="translationType">
              <template v-slot:label>
                <div>{{$t('dialogs.add-order-files.choose')}} <strong class="primary--text">{{$t('dialogs.add-order-files.file-type')}}</strong></div>
              </template>
              <v-radio :label="$t('dialogs.add-order-files.original')" value="Original"></v-radio>
              <v-radio :label="$t('dialogs.add-order-files.translated')" value="Translated"></v-radio>
              <v-divider></v-divider>
              <v-radio :label="$t('dialogs.add-order-files.reference')" value="Reference"></v-radio>
              <v-radio :label="$t('dialogs.add-order-files.tm')" value="TM"></v-radio>
              <v-radio :label="$t('dialogs.add-order-files.glossary')" value="Glossary"></v-radio>
              <v-radio :label="$t('dialogs.add-order-files.finance')" value="Finance"></v-radio>
              <v-radio :label="$t('dialogs.add-order-files.other')" value="Other"></v-radio>
            </v-radio-group>

            <v-file-input v-if="translationType" v-model="files" multiple prepend-icon="mdi-attachment-plus" counter
                          show-size clearable
                          :placeholder="$t('dialogs.add-order-files.select-files')" small-chips outlined>
              <template v-slot:selection="{ index, text }">
                <v-chip color="deep-purple accent-4" dark label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <v-btn color="blue darken-1" @click="sendFiles" :disabled="!files.length || loading" :loading="loading">
              {{$t('buttons.save-files')}}
            </v-btn>

            <v-container>
              <v-progress-linear v-if="loading" v-model="progress" color="blue" height="27">
                <strong>{{$t('loadings.loading-progress')}} {{ progress }}% ({{currentFileNo}}/{{this.files.length}}) </strong>
              </v-progress-linear>
            </v-container>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" v-on:click="$emit('close-dialog')" :disabled="loading" :loading="loading">
              {{$t('buttons.close')}}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import {useFiles} from "../../stores/filesStore";

  const filesStore = useFiles()

  export default {
    props: ['open-dialog', 'order'],
    name: "AddOrderFilesDialog",
    data: () => ({
      currentFileNo: 0,
      progress: 0,
      loading: false,
      files: [],
      translationType: null,
      description: '',
      imgName: 'addFilesDialog.jpg'
    }),
    methods: {
      async sendFiles() {
        console.log('orderId: ', this.order.orderId);
        this.loading = true;
        let action = 'upload'
        console.log('files: ', this.files)
        let objectType = this.files.length > 1 ? 'Files' : 'File'
        for (let i = 0; i < this.files.length; i++) {
          this.currentFileNo = i + 1;
          const formData = new FormData();
          formData.append('file', this.files[i])
          formData.append('fileName', this.files[i].name);
          formData.append('fileExtension', this.files[i].name.split('.').pop());
          formData.append('fileSize', this.files[i].size);
          formData.append('orderId', this.order.orderId);
          formData.append('fileType', this.translationType);
          formData.append('description', this.description);
          formData.append('userId', this.currentUser.id);
          await AXIOS.post('/api/fileUpload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => this.progress = (progressEvent.loaded * 100 / progressEvent.total).toFixed(0)
          })
            .then(response => {
              filesStore.addFile(response.data)
            })
        }
        this.$emit('refresh-file-list');
        this.$emit('close-dialog');
        this.files = [];
        this.translationType = null;
        this.userNotification(this.response, action, objectType);
        this.loading = false;
      },
    },
  }
</script>

<style scoped>

</style>
